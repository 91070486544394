import serialize from 'serialize-javascript';

const storeHoursMock = {
  monday: { open: '6:00', close: '21:00' },
  tuesday: { open: '6:00', close: '21:00' },
  wednesday: { open: '6:00', close: '21:00' },
  thursday: { open: '6:00', close: '21:00' },
  friday: { open: '6:00', close: '21:00' },
  saturday: { open: '6:00', close: '21:00' },
  sunday: { open: '8:00', close: '20:00' }
};

export const ContextBuilder = {
  server({ expConfiguration = {}, requestData = {}, hosts = {} }) {
    const experienceContext = {
      channel: requestData.channel || 'desktop',
      cookie: {
        adobeCookie: requestData.adobeCookie
      },
      configService: {
        ...expConfiguration,
        searchStream: expConfiguration?.['search.stream'],
        searchStreamLimit: expConfiguration?.['search.streamLimit'],
      },
      hosts,
      path: (requestData.route && requestData.route.path) || '/',
      store: {
        storeHours: requestData.storeHours || storeHoursMock,
        storeId: requestData.storeId || '121',
        storeName: requestData.storeName || 'Cumberland',
        storeZip: requestData.localStoreZip || '30339'
      },
      segment: requestData.segment,
      dataType: requestData.dataType,
      isDebugMode: requestData.isDebugMode,
      isLocal: typeof requestData.isLocal !== 'undefined'
        ? requestData.isLocal
        : false,
      customer: requestData.customer,
      isConsumerApp: requestData.isConsumerApp,
      localStoreZip: requestData.localStoreZip,
      deliveryZip: requestData.deliveryZip,
      referer: requestData.referer,
      experiment: requestData.experiment || {},
    };
    return {
      contextScript: `
      <script>window.__EXPERIENCE_CONTEXT__ =
      {
        "cookie": ${serialize(experienceContext.cookie)},
        "channel": ${serialize(experienceContext.channel)},
        "hosts": ${serialize({ ...experienceContext.hosts, api: '', www: '' })},
        "path": ${serialize(experienceContext.path)},
        "store": ${serialize(experienceContext.store)},
        "segment": ${serialize(experienceContext.segment)},
        "dataType": ${serialize(experienceContext.dataType)},
        "isDebugMode": ${serialize(experienceContext.isDebugMode)},
        "isLocal": ${serialize(experienceContext.isLocal)},
        "customer": ${serialize(experienceContext.customer)},
        "isConsumerApp": ${serialize(experienceContext.isConsumerApp)},
        "localStoreZip": ${serialize(experienceContext.localStoreZip)},
        "deliveryZip": ${serialize(experienceContext.deliveryZip)},
        "nearByStores": ${serialize(experienceContext.nearByStores)},
        "referer": ${serialize(experienceContext.referer)},
        "experiment": ${serialize(experienceContext.experiment)}
      }
      </script>
      `,
      experienceContext
    };
  },
  client({ expConfiguration = {} } = { expConfiguration: {} }) {
    const experienceContext = {
      cookie: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.cookie) || {},
      channel: window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.channel,
      hosts: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.hosts) || {},
      path: window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.path,
      store: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.store) || {},
      segment: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.segment) || '',
      dataType: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.dataType) || '',
      isDebugMode: (window.__EXPERIENCE_CONTEXT__ && typeof window.__EXPERIENCE_CONTEXT__.isDebugMode !== 'undefined')
        ? window.__EXPERIENCE_CONTEXT__.isDebugMode
        : false,
      isLocal: window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.isLocal,
      customer: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.customer) || {},
      isConsumerApp: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.isConsumerApp),
      localStoreZip: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.localStoreZip) || '',
      deliveryZip: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.deliveryZip) || '',
      nearByStores: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.nearByStores) || [],
      referer: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.referer) || '',
      experiment: (window.__EXPERIENCE_CONTEXT__ && window.__EXPERIENCE_CONTEXT__.experiment) || {},
      configService: {
        ...expConfiguration,
        searchStream: expConfiguration?.['search.stream'],
        searchStreamLimit: expConfiguration?.['search.streamLimit'],
      }
    };
    return {
      experienceContext
    };
  },
};
