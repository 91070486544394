/* eslint-disable no-else-return */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import digitalData from 'digital-data';
// import { cart } from '../cart/cart';

let enableCheckoutAdobeAnalytics = false;
let override = null;
let addressValidationInteractions = [];

const SELECTED_FFM_TYPES = {
  BOPIS: 'bopis',
  BOSS: 'boss',
  STH: 'sth',
  BODFS: 'express delivery',
  APPL_DELIV: 'direct delivery'
};

const ADDON_TYPES = {
  HDPP: 'protectionPlan',
  ASSEMBLY: 'Assembly',
  FREE_ASSEMBLY: 'freeAssemblyAtStore',
  INSTALL: 'Install',
  PRO_INSTALL: 'PRInstall',
  PAINTER: 'Painter',
  SUBSCRIPTION: 'subscription'
};

const GIFT_CARD = 'GIFT_CARD';

const EVENTS = {
  STANDARD_CHECKOUT: 'standard-checkout',
  TOOL_RENTAL_CHECKOUT: 'toolrental-checkout',
  AMERIGAS_CHECKOUT: 'amerigas-checkout',
  EXCHANGE_CHECKOUT: 'exchange-checkout',
  STANDARD_SUBMIT: 'standard-submit',
  STANDARD_SUBMIT_SUCCESS: 'standard-submit-success',
  AMERIGAS_SUBMIT: 'amerigas-submit',
  AMERIGAS_SUBMIT_SUCCESS: 'amerigas-submit-success',
  EXCHANGE_SUBMIT: 'exchange-submit',
  TOOL_RENTAL_SUBMIT: 'toolrental-submit',
  TOOL_RENTAL_SUBMIT_SUCCESS: 'toolrental-submit-success',
  ADDING_CREDIT_CARD: 'adding-credit-card',
  ADDING_PAYPAL: 'adding-paypal',
  DELIVERY_CALENDAR_CLICK: 'delivery-calendar-click',
  ADDRESS_VALIDATION: 'address-validation',
  ADDRESS_VALIDATION_INFO: 'address-validation-info',
  ERROR_ADVISORY: 'error-advisory',
  CREDIT_CARD_VALIDATION: 'credit-card-validation',
  CC_BIN_RANGE_RECIEVED: 'cc-bin-range-received',
  CC_FIRST_INTERACTION: 'cc-first-interaction',
  // CC_BRAND_RECIEVED: 'cc-brand-received',
  CREDIT_CARD_ERROR: 'credit-card-error',
  FULFILLMENT_CHANGE: 'fulfillment-change',
  CVV_SUPPRESSED_ON_USER_UPDATE: 'cvv-suppressed-on-user-update',
  // CVV_SUPPRESSED_ON_PAGE_LOAD: 'cvv-suppressed-on-page-load',
  SCHEDULE_DELIVERY: 'schedule-delivery',
  POST_PAGE_ERROR: 'post page error',
  ORANGE_PAY_INIT: 'orange-pay-init',
  GIFTCARDRELOAD_CHECKOUT: 'giftcardReload-checkout',
  GIFTCARDRELOAD_SUBMIT: 'giftcardReload-submit-success',
  APPLEPAY_CLICK: 'apple-pay-click',
  APPLEPAY_SUBMIT: 'apple-pay-submit',
  APPLEPAY_SUBMIT_SUCCESS: 'apple-pay-submit-success',
};

// ---------- from thd-analytics-utils  ------------------------------------------------------------ //

let integration = '';
let cvvSuppressedOnPageLoad = false;

export const setAnalytics = () => {
  const _ddo = digitalData.init();
  _ddo.site = {
    ..._ddo.site,
    integration
  };

  if (cvvSuppressedOnPageLoad) {
    _ddo.content = _ddo.content || {};
    _ddo.content.feature = _ddo.content.feature || {};
    _ddo.content.feature.suppressedFields = _ddo?.content?.feature?.suppressedFields?.length > 0 ? ',cvv' : 'cvv';
  }

  if (navigator?.permissions?.query) {
    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        _ddo.user = {
          ..._ddo.user,
          localization: {
            ..._ddo?.user?.localization,
            gpsPermission: result?.state?.length ? 'gps access ' + result?.state : null
          }
        };
      });
    } catch (err) {
      console.error(err);
    }
  }

  window._T = window._T || {};

  if (typeof window._T.pageData === 'undefined') {
    window._T.pageData = [];
  }
  window._T.pageData.push(_ddo);
};

// ---------- START OF UTILS FUNCTIONS  ------------------------------------------------------------ //
const isExpressPayPal = () => {
  try {
    return (
      typeof window !== 'undefined'
      && window?.location?.href
      && window?.location?.href?.indexOf('expressPaypal') > -1
    ) || false;
  } catch (err) {
    console.error('Error in isExpressPayPal()', err);
  }
};

const getDefaultCreditCard = (savedPaymentCards = []) => {
  const firstCreditCard = savedPaymentCards?.find((card) => card?.type === 'CC') || null;
  const defaultCC = savedPaymentCards?.find((card) => card?.isDefault && card?.type === 'CC') || null;
  return defaultCC || firstCreditCard;
};

const handleCvvSuppressedOnPageLoad = (output) => {
  cvvSuppressedOnPageLoad = true;
};

const publishPageLoadAnalytics = ({ eventObject = {} }) => {
  try {
    if (!enableCheckoutAdobeAnalytics) {
      console.warn('Checkout Adobe Analytics (Page Load) is disabled', eventObject);
      return;
    }
    digitalData.cart = eventObject?.cart || {};
    digitalData.page = eventObject?.page || {};
    digitalData.site = eventObject?.site || {};

    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    setAnalytics(digitalData);
  } catch (error) {
    console.error('Error in publishPageLoadAnalytics()', error);
  }
};

const publishEvent = ({ eventObject }) => {
  try {
    if (!enableCheckoutAdobeAnalytics) {
      console.warn('Checkout Adobe Analytics (Event) is disabled', eventObject);
      return;
    }
    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    digitalData.event.push(eventObject);
  } catch (error) {
    console.error('Error in publishEvent()', error);
  }
};

const getSiteIntegration = ({ domain }) => {
  try {
    if (domain?.toUpperCase() === 'AAFES') {
      return 'aafes';
    }
    if (domain?.toUpperCase() === 'NEX') {
      return 'nex';
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

const bopis = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service?.type === 'bopis');
  return bopisFulfillment || null;
};
const boss = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((service) => service?.type === 'boss');
  return bossFulfillment || null;
};
const sth = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const sthFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'sth');
  return sthFulfillment || null;
};
const bodfs = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'express delivery');
  return bodfsFulfillment || null;
};
const applianceDelivery = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'direct delivery');
  return bodfsFulfillment || null;
};

const buildEtaForAddon = ({ item, addon }) => {
  let eta = {};

  switch (addon?.type) {
  case ADDON_TYPES.HDPP:
    eta = {
      sthStartDate: 'NA', // <-- we do NOT have HDPP dates available to use.
      sthEndDate: 'NA' // we had it legacy mcc-cart
    };
    break;
  default:
    break;
  }

  return eta;
};

const getAddonFromItem = ({ item, addonType }) => {
  try {
    if (!item?.product?.addons || !addonType) { return false; }
    return (item?.product?.addons?.find((addon) => addon?.type === addonType));
  } catch (error) {
    console.error('Error occurred in getAddonFromItem()', error);
    return {};
  }
};

const isProReferralAddon = (addonType = '') => {
  return (addonType === ADDON_TYPES.PRO_INSTALL || addonType === ADDON_TYPES.PAINTER);
};

const getItemSkuForAnalytics = ({ item, addon }) => {
  try {
    const ASSEMBLY_SKU = '764092'; // <-- must return this as itemSKU for bopis or bodfs free store assembly

    switch (addon?.type) {
    case ADDON_TYPES.FREE_ASSEMBLY:
      return ASSEMBLY_SKU;
    case ADDON_TYPES.HDPP:
    case ADDON_TYPES.ASSEMBLY:
    case ADDON_TYPES.INSTALLL:
      return addon?.configAttr || '';
    case ADDON_TYPES.PRO_INSTALL:
    case ADDON_TYPES.PAINTER:
      return item?.product?.itemId || '';
    default:
      return item?.product?.itemId || '';
    }
  } catch (err) {
    console.log(err);
  }
  return '';
};

const getFfmMethodForAnalytics = (ffmMethod) => {
  switch (ffmMethod) {
  case SELECTED_FFM_TYPES.BOPIS:
    return 'bopis';
  case SELECTED_FFM_TYPES.BOSS:
    return 'boss';
  case SELECTED_FFM_TYPES.STH:
    return 'sth';
  case SELECTED_FFM_TYPES.BODFS:
    return 'bodfs';
  case SELECTED_FFM_TYPES.APPL_DELIV:
    return 'appl';
  default:
    return '';
  }
};

const getFulfillmentMethodForAddons = ({ item, addon }) => {
  const isFreeAssemblyAtStore = addon?.type === ADDON_TYPES.FREE_ASSEMBLY;
  const ffmMethod = isFreeAssemblyAtStore
    ? getFfmMethodForAnalytics(item?.selectedFulfillmentType) // could be 'bopis' or 'bodfs'
    : 'sth';
  return ffmMethod;
};

const getSubscriptionFrequency = (frequency = '') => {
  try {
    const frequencyMap = {
      '1_3': '1 months', // every 1 month
      '2_3': '2 months', // every 2 months, etc
      '3_3': '3 months',
      '4_3': '4 months',
      '5_3': '5 months',
      '6_3': '6 months',
      '9_3': '9 months',
      '12_3': '12 months'
    };
    return frequency ? frequencyMap[frequency] : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

const getPickUpDesk = ({ item }) => {
  try {
    const curbside = 'curbside';
    const inStore = 'in-store';
    const selectedFFM = item?.selectedFulfillment || '';
    const fulfillment = item?.product?.fulfillment || {};
    const isBopis = selectedFFM === SELECTED_FFM_TYPES.BOPIS;
    const isBoss = selectedFFM === SELECTED_FFM_TYPES.BOSS;

    if (isBopis) {
      const bopisService = bopis(fulfillment);
      const isBopisPrimaryStoreCurbside = bopisService?.locations?.[0]?.curbsidePickupFlag || false;
      const isBopisSecondaryStoreCurbside = bopisService?.locations?.[1]?.curbsidePickupFlag || false;
      if (isBopisSecondaryStoreCurbside || isBopisPrimaryStoreCurbside) { return curbside; }
      return inStore;
    } else if (isBoss) {
      const bossService = boss(fulfillment);
      const isBossPrimaryStoreCurbside = bossService?.locations?.[0]?.curbsidePickupFlag || false;
      const isBossSecondaryStoreCurbside = bossService?.locations?.[1]?.curbsidePickupFlag || false;
      if (isBossSecondaryStoreCurbside || isBossPrimaryStoreCurbside) { return curbside; }
      return inStore;
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

const getStoreOrZip = ({ item }) => {
  try {
    const selectedFFM = item?.selectedFulfillment || '';
    const fulfillment = item?.product?.fulfillment || {};
    let locations = [];

    switch (selectedFFM) {
    case SELECTED_FFM_TYPES.BOPIS:
      const bopisService = bopis(fulfillment);
      locations = bopisService?.locations || [];
      const isBopisPrimaryStoreOOS = !locations?.[0]?.inventory?.isInStock;
      if (locations?.length > 1 && isBopisPrimaryStoreOOS) {
        return locations?.[1]?.locationId;
      }
      return locations?.[0]?.locationId;

    case SELECTED_FFM_TYPES.BOSS:
      const bossService = boss(fulfillment);
      locations = bossService?.locations || [];
      const isBossPrimaryStoreOOS = !locations?.[0]?.inventory?.isInStock;
      // if nearbyStore
      if (locations?.length > 1 && isBossPrimaryStoreOOS) {
        return locations?.[1]?.locationId;
      }
      return locations?.[0]?.locationId;

    case SELECTED_FFM_TYPES.BODFS:
      const bodfsService = bodfs(fulfillment);
      locations = bodfsService?.locations || [];
      return locations?.[0]?.zipCode;

    case SELECTED_FFM_TYPES.APPL_DELIV:
      const applDeliveryService = applianceDelivery(fulfillment);
      locations = applDeliveryService?.locations || [];
      return locations?.[0]?.zipCode;

    default:
      break;
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

const buildItemETA = (item) => {
  let eta = {};

  const fulfillment = item?.product?.fulfillment || {};
  const selectedFFM = item?.selectedFulfillment || '';

  switch (selectedFFM) {
  case SELECTED_FFM_TYPES.BOSS:
    const bossService = boss(fulfillment);
    eta = {
      bossStartDate: bossService?.deliveryDates?.startDate || 'NA',
      bossEndDate: bossService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.STH:
    const sthService = sth(fulfillment);
    eta = {
      sthStartDate: sthService?.deliveryDates?.startDate || 'NA',
      sthEndDate: sthService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.BODFS:
    const bodfsService = bodfs(fulfillment);
    eta = {
      bodfsStartDate: bodfsService?.deliveryDates?.startDate || 'NA',
      bodfsEndDate: bodfsService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.APPL_DELIV:
    const applDeliveryService = applianceDelivery(fulfillment);
    eta = {
      applStartDate: applDeliveryService?.expectedArrival || 'NA',
      applEndDate: applDeliveryService?.expectedArrival || 'NA'
    };
    break;
  default:
    break;
  }

  return eta;
};

const buildItemFulfillment = ({ item }) => {
  const selectedFulfillment = item?.selectedFulfillment;
  const isPickupFfm = selectedFulfillment === SELECTED_FFM_TYPES.BOPIS
    || selectedFulfillment === SELECTED_FFM_TYPES.BOSS;

  const itemFulfillment = {
    method: getFfmMethodForAnalytics(selectedFulfillment),
    backOrder: item?.product?.fulfillment?.backordered ? 'yes' : 'no',
  };

  if (isPickupFfm) {
    itemFulfillment.store = getStoreOrZip({ item });
    itemFulfillment.pickUpDesk = getPickUpDesk({ item });
  } else if (selectedFulfillment !== SELECTED_FFM_TYPES.STH) {
    itemFulfillment.zip = getStoreOrZip({ item });
  }

  return itemFulfillment;
};

const getItemDiscountAmount = ({ lineItemId = '', promoItemsArray = [] }) => {
  try {
    if (!lineItemId || !promoItemsArray?.length) {
      return '';
    }
    const promoItemData = promoItemsArray?.find((promoItem) => promoItem?.appliedOn === lineItemId);
    return promoItemData?.appliedDiscount?.substring(1) || '';
  } catch (error) {
    console.log(error);
  }
  return '';
};

const transformAddonToAnalyticsStructure = ({ item, cartData }) => {
  try {
    const addons = item?.product?.addons || [];
    const addonsForAnalytics = [];

    if (addons?.length) {
      addons.forEach((addon) => {
        const isValidAddonForAnalytics = [
          ADDON_TYPES.HDPP,
          ADDON_TYPES.PRO_INSTALL,
          ADDON_TYPES.PAINTER,
          ADDON_TYPES.FREE_ASSEMBLY
        ].includes(addon?.type);
        const isHdppAddon = addon?.type === ADDON_TYPES.HDPP;
        const isHddpForAppl = isHdppAddon && item?.selectedFulfillment === SELECTED_FFM_TYPES.APPL_DELIV;
        const isAddonSelected = addon?.selected || false;
        const isProReferral = isProReferralAddon(addon?.type);

        // only publish *SELECTED* addon analytics, UNLESS that addon is Pro Referral.
        // We always allow pro referral to publish
        if (isValidAddonForAnalytics && !isHddpForAppl && (isAddonSelected || isProReferral)) {
          const price = addon?.totalPrice ? addon?.totalPrice?.toFixed(2) : '0.00';

          const analyticsObject = {
            quantity: addon?.quantity ? addon?.quantity?.toString() : '1',
            price: {
              basePrice: price
            },
            productInfo: {
              sku: getItemSkuForAnalytics({ item, addon })
            },
            fulfillment: {
              method: getFulfillmentMethodForAddons(item?.selectedFulfillment)
            }
          };
          const isFreeAssemblyAtStore = addon?.type === ADDON_TYPES.FREE_ASSEMBLY;

          if (isHdppAddon) {
            analyticsObject.productInfo.eta = buildEtaForAddon({ item, addon });
            analyticsObject.fulfillment.backOrder = item?.product?.fulfillment?.backordered ? 'yes' : 'no';
          } else if (isFreeAssemblyAtStore) {
            analyticsObject.productInfo.assembly = 'free in-store';
          } else if (isHddpForAppl) {
            analyticsObject.price.applDeliveryFee = cartData?.totals?.applianceDeliveryCharge?.toFixed(2) || '';
          } else if (isProReferral) {
            const isProReferralSelected = addon?.selected;
            if (isProReferralSelected) {
              analyticsObject.productInfo.proReferralSelected = true;
            } else {
              analyticsObject.productInfo.proReferralDisplayed = true;
            }
          }

          addonsForAnalytics.push(analyticsObject);
        }
      });
    }
    return addonsForAnalytics;
  } catch (error) {
    console.error(error);
  }
};

const getPromoCodeData = ({ cartData }) => {
  try {
    if (cartData?.promos?.length) {
      return cartData?.promos?.find((promo) => promo?.promoCode
        && promo?.promoItems?.length
        && promo?.type?.toLowerCase() === 'order level');
    }
  } catch (error) {
    console.error(error);
  }
  return {};
};

const setItem = ({ item, promoCodeData }) => {
  const totalPrice = item?.product?.pricing?.total?.toFixed(2) || '';
  const itemAnalytics = {
    quantity: item?.quantity || '',
    price: {
      basePrice: totalPrice,
      discountCode: promoCodeData?.promoCode || '',
      discountAmount: getItemDiscountAmount({ lineItemId: item?.id, promoItemsArray: promoCodeData?.promoItems }) || ''
    },
    productInfo: {
      sku: item?.product?.identifiers?.itemId || '',
      superSKU: /* item?.product?.identifiers?.isSuperSku
        ? item?.product?.identifiers?.itemId || '' // <--- replace with SSKU number
        :  */ item?.product?.identifiers?.itemId || '',
      productName: item?.product?.identifiers?.productLabel || '',
      manufacturer: item?.product?.identifiers?.brandName || '',
      subCategory2: item?.product?.identifiers?.productLabel || '',
    },
    fulfillment: buildItemFulfillment({ item })
  };

  const isGiftCard = item?.product?.identifiers?.productType?.toUpperCase() === GIFT_CARD;

  if (isGiftCard) {
    itemAnalytics.price.giftCardPrice = totalPrice;
    itemAnalytics.price.basePrice = '0.00';
  }

  const subscriptionAddonData = getAddonFromItem({ item, addonType: ADDON_TYPES.SUBSCRIPTION });
  const isSubscriptionAddonSelected = subscriptionAddonData?.selected || false;
  if (subscriptionAddonData && isSubscriptionAddonSelected) {
    itemAnalytics.subscription = {
      frequency: getSubscriptionFrequency(subscriptionAddonData?.configAttr)
    };
  }

  // item ETA applies to all ffm EXCEPT bopis
  if (item?.selectedFulfillment !== SELECTED_FFM_TYPES.BOPIS) {
    itemAnalytics.productInfo.eta = buildItemETA(item);
  }
  return itemAnalytics;
};

// payment method for credit cards and for HD cards
const mapPaymentType = (paymentType = '') => {
  try {
    switch (paymentType) {
    case 'VI':
      return 'visa';
    case 'MA':
      return 'mc';
    case 'AX':
      return 'amex';
    case 'DS':
      return 'disc';
    case 'HC':
      return 'hdcon';
    case 'HP':
      return 'hdcom';
    case 'HR':
      return 'hdcomrev';
    case 'HA':
      return 'hdhouse';
    case 'HI':
      return 'hdimprover';
    default:
      return paymentType.toLowerCase();
    }
  } catch (evt) {
    console.error('Unable to set digitalData savedPaymentMethod field', evt);
    return '';
  }
};

const getPaymentMethods = (paymentDetails = {}) => {
  try {
    const paymentMode = paymentDetails?.paymentMode?.toLowerCase() || '';
    if (paymentMode?.includes('paypal') || isExpressPayPal()) {
      return ['ppl'];
    }

    let paymentArr = [];

    // credit card or HD card
    const cardType = paymentDetails?.paymentCard?.cardType || paymentDetails?.newCardFields?.reportPaymentMethodCode;
    const cardPaymentMethod = mapPaymentType(cardType);
    if (cardPaymentMethod?.length) {
      paymentArr.push(cardPaymentMethod);
    }

    // gift cards
    if (paymentDetails?.isGiftCardUsed) {
      paymentArr.push('gift card');
    }

    // pro xtra perks
    const perkGiftCardsArray = Object.values(paymentDetails?.perkGiftCards || {});
    const hasUsedPerkGiftCards = perkGiftCardsArray?.some(
      (card) => card.giftCardAmountUsed && card.giftCardAmountUsed > 0) || false;
    if (hasUsedPerkGiftCards) {
      paymentArr.push('pxdollars');
    }

    return paymentArr?.length > 0 ? paymentArr : '';
  } catch (err) {
    console.error('analytics getPaymentMethods() error', err);
  }
};

const setCartObject = ({
  checkoutType, eventObject, cartData, paymentDetails = {}, quoteSource
}) => {
  try {
    const items = cartData?.items || [];
    const promoCodeData = getPromoCodeData({ cartData });
    let processedItems = [];
    // eslint-disable-next-line no-unused-expressions
    items?.forEach((item) => {
      processedItems.push(setItem({ item, promoCodeData }));

      // Add certain Product-Add-Ons to item array for analytics
      const addonsArrayForAnalytics = transformAddonToAnalyticsStructure({ item, cartData });
      processedItems = processedItems.concat(addonsArrayForAnalytics);
    });

    let discountAmount = '';
    if (promoCodeData?.appliedDisc) {
      discountAmount = (promoCodeData?.appliedDisc * -1)?.toFixed(2); // transform -1.23 into '1.23' for analytics
    }

    const cartObj = {
      attributes: {
        OMS: 'aurora'
      },
      cartID: cartData?.cartId,
      item: processedItems,
      price: {
        cartTotal: cartData?.totals?.total?.toFixed(2) || '',
        discountCode: promoCodeData?.promoCode || '',
        discountAmount,
        paymentMethod: getPaymentMethods(paymentDetails),
        applDeliveryFee: cartData?.totals?.applianceDeliveryCharge?.toFixed(2) || ''
      },
      uniqueSkuCount: cartData?.items?.length || '',
      quantityTotal: cartData?.itemCount || '',
    };
    if (checkoutType === 'standard') {
      cartObj.quoteSource = quoteSource;
    }

    eventObject.cart = cartObj;
  } catch (error) {
    console.error(error);
  }
};

const setStash = (eventObject, variables = {}) => {
  eventObject.stash = {
    ...variables
  };
};

const setPageInfo = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.pageInfo = {
    abTest: '',
    pageLoadEvent: 'opc view',
    pageTemplate: 'checkout>opc',
    platform: 'nucleus',
    ...variables
  };
};

const setCategory = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.category = {
    primaryCategory: 'checkout',
    subCategory1: '',
    subCategory2: '',
    ...variables
  };
};

const setEventInfo = (eventObject, variables = {}) => {
  eventObject.eventInfo = {
    ...variables
  };
};

const setSite = (eventObject, variables = {}, output = {}) => {
  integration = getSiteIntegration({ domain: output?.allowed_domains });
  const isB2B = output?.isB2BCustomer || (output?.isB2CCustomer === false) || false;
  const isBeta = output?.isBeta || false;

  eventObject.site = {
    betaSite: isBeta ? 'beta' : 'prod',
    businessType: isB2B ? 'b2b' : '',
    experienceType: output?.channel,
    siteID: isB2B ? 'b2bSite' : '',
    siteVersion: '',
    integration,
    ...variables
  };
};

const setForm = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.form = {
    rentalLeadID: ''
  };
};

const getDefaultPaymentTypes = (savedPaymentCards, paymentDetails) => {
  try {
    const defaultPaymentTypes = [];
    const defaultCreditCard = getDefaultCreditCard(savedPaymentCards);
    if (defaultCreditCard) {
      const paymentMethod = mapPaymentType(defaultCreditCard?.cardType);
      defaultPaymentTypes.push(paymentMethod);
    }
    if (paymentDetails?.isPayPal || paymentDetails?.isExpressPayPal) {
      defaultPaymentTypes.push('ppl');
    }

    return defaultPaymentTypes?.join('|');
  } catch (evt) {
    console.error('Unable to access digitalData subobject in getDefaultPaymentTypes', evt);
  }
  return '';
};

const setMyAccount = (eventObject, variables = {}, output, eventType) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.myAccount = {
    savedPaymentMethod: getDefaultPaymentTypes(output?.savedPaymentCards, output?.paymentDetails)
  };
};

const setContent = (output) => {
  const defaultCreditCard = getDefaultCreditCard(output?.savedPaymentCards);
  if (defaultCreditCard?.hideCvv) {
    handleCvvSuppressedOnPageLoad();
  }
};

// ---------- END OF UTILS FUNCTIONS ------------------------------------------------------------ //

const standardCheckout = (output) => {
  const eventObject = {};
  setCartObject({
    checkoutType: 'standard',
    eventObject,
    cartData: output?.cartData,
    paymentDetails: output?.paymentDetails,
    quoteSource: output?.quoteSource,
  });
  if (isExpressPayPal()) {
    setPageInfo(eventObject, {
      pageName: 'checkout>paypal order review',
      pageType: 'checkout: opc',
      pageLoadEvent: 'paypal order review',
      pageVersion: 'opc'
    });
    setCategory(eventObject, {
      subCategory1: 'checkout>paypal order review',
      subCategory2: 'checkout>paypal order review'
    });
  } else {
    setPageInfo(eventObject, {
      pageName: 'checkout>opc',
      pageType: 'checkout: opc',
      pageVersion: 'opc'
    });
    const standardCategory = 'checkout>opc';
    setCategory(eventObject, {
      subCategory1: standardCategory,
      subCategory2: standardCategory
    });
  }

  setSite(eventObject, {}, output);
  setMyAccount(eventObject, {}, output, EVENTS.STANDARD_CHECKOUT);
  setContent(output);
  publishPageLoadAnalytics({ eventObject });
};

const exchangeCheckout = (output) => {
  const eventObject = {};
  setCartObject({ checkoutType: 'exchange', eventObject, cartData: output?.cartData });
  setPageInfo(eventObject, {
    abTest: '',
    pageName: 'checkout>opc',
    pageType: 'checkout: opc',
    pageVersion: 'opc'
  });

  const standardCategory = 'checkout>opc';
  setCategory(eventObject, {
    subCategory1: standardCategory,
    subCategory2: standardCategory
  });

  setSite(eventObject, {}, output);
  setMyAccount(eventObject, {}, output, EVENTS.EXCHANGE_CHECKOUT);
  publishPageLoadAnalytics({ eventObject });
};

const toolRentalCheckout = (output) => {
  const eventObject = {};
  setCartObject({
    checkoutType: 'toolrental',
    eventObject,
    cartData: output?.cartData,
    paymentDetails: output?.paymentDetails
  });
  const itemId = output?.cartData?.items?.[0]?.product?.itemId || '';
  setPageInfo(eventObject, {
    abTest: '',
    pageName: 'product rental>' + itemId + '>reserve checkout',
    pageType: 'tool:productRental:reserveCheckout',
    pageLoadEvent: 'reservation checkout view',
  });

  const toolrentalCategory = 'product rental>' + itemId + '>reserve checkout';
  setCategory(eventObject, {
    primaryCategory: toolrentalCategory,
    subCategory1: toolrentalCategory,
    subCategory2: toolrentalCategory
  });

  setSite(eventObject, {}, output);
  setForm(eventObject, {});
  setMyAccount(eventObject, {}, output, EVENTS.TOOL_RENTAL_CHECKOUT);
  setContent(output);
  publishPageLoadAnalytics({ eventObject });
};

const amerigasStash = (output) => {
  const eventObject = {};
  setCategory(eventObject, {
    primaryCategory: 'stash'
  });
  setEventInfo(eventObject, {
    eventName: 'cart add location'
  });
  eventObject.eventMode = 'stash';
  setStash(eventObject, {
    component: '',
    pageType: 'plp',
    section: '',
    sharedSection: 'self service',
    target: 'atc',
    item: [{
      index: '',
      productID: ''
    }]
  });
  publishEvent({ eventObject });
};

const amerigasCheckout = (output) => {
  const eventObject = {};
  setCartObject({
    checkoutType: 'amerigas',
    eventObject,
    cartData: output?.cartData,
    paymentDetails: output?.paymentDetails
  });
  const itemId = output?.cartData?.items?.[0]?.product?.itemId || '';
  setPageInfo(eventObject, {
    pageName: 'amerigas>' + itemId + '>propane tank checkout',
    pageType: 'tool:productRental:reserveCheckout',
    pageLoadEvent: 'amerigas checkout view',
  });

  const amerigasCategory = 'amerigas>' + itemId + '>checkout';
  setCategory(eventObject, {
    primaryCategory: amerigasCategory,
    subCategory1: amerigasCategory,
    subCategory2: amerigasCategory
  });

  setSite(eventObject, {}, output);
  setMyAccount(eventObject, {}, output, EVENTS.AMERIGAS_CHECKOUT);
  setContent(output);
  publishPageLoadAnalytics({ eventObject });
};

// const handleNavigationEvent = ({ primaryCategory, eventName }) => {
//   try {
//     const eventObject = {};
//     setCategory(eventObject, {
//       primaryCategory
//     });
//     setEventInfo(eventObject, {
//       eventName
//     });
//     publishEvent({ eventObject });
//   } catch (error) {
//     console.error(error);
//   }
// };

const handleEvent = (primaryCategory, eventName) => {
  try {
    const eventObject = {
      category: {
        primaryCategory
      }
    };
    setEventInfo(eventObject, {
      eventName
    });
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

// const handleCreditCardBrandReceived = (output) => {
//   try {
//     const eventObject = {
//       category: {
//         primaryCategory: 'interaction'
//       }
//     };
//     setEventInfo(eventObject, {
//       eventName: 'credit card brand',
//       brand: output?.brand
//     });
//     publishEvent({ eventObject });
//   } catch (error) {
//     console.error(error);
//   }
// };

const handleFulfillmentChange = (output) => {
  try {
    const eventObject = {
      category: {
        primaryCategory: 'opc view modification'
      },
      item: [
        {
          productInfo: {
            sku: output?.sku,
          },
          fulfillment: {
            method: output?.newFulfillment,
            previousMethod: output?.prevFulfillment,
            store: output?.store || '',
            zip: output?.zip || ''
          }
        }
      ]
    };
    setEventInfo(eventObject, {
      eventName: 'opc fulfillment change',
    });
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

const handleBinRangeReceived = (output) => {
  try {
    const eventObject = {
      category: {
        primaryCategory: 'interaction'
      }
    };
    setEventInfo(eventObject, {
      eventName: 'credit card bin range received',
      binRange: output?.binRange
    });
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

const handleError = ({
  eventName, errorType, displayType, pageSection, message, code
}) => {
  try {
    const eventObject = {
      category: {
        primaryCategory: 'error'
      },
      eventInfo: {
        eventName,
        error: [{
          type: errorType,
          displayType,
          pageSection,
          message,
          code
        }]
      }
    };
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

// const getPickupLocation = (cart) => {
//   const isCurbside = cart?.itemGrouping?.byFulfillment?.pickup?.[0]?.location?.curbsidePickupFlag || false;
//   return isCurbside ? 'curbside' : 'in-store';
// };

const coreSubmit = (output) => {
  const eventObject = {

    category: {
      primaryCategory: 'button submission',
      addressValidation: [...addressValidationInteractions],
    },
    eventInfo: {
      eventName: 'order submission'
    }
  };
  publishEvent({ eventObject });
};

// const getPickupOption = (cart) => {
//   return cart?.profileData?.firstName?.length > 0 ? 'Y' : 'N';
// };

// const hasAppliedPromo = (cart) => {
//   const hasApplied = cart?.promos?.find((promo) => {
//     return promo?.appliedDisc < 0;
//   });
//   return hasApplied ? 'Y' : 'N';
// };

const savePaymentToProfile = (paymentType) => {
  const eventObject = {
    category: {
      primaryCategory: 'button submission'
    },
    eventInfo: {
      eventName: 'my account settings',
    },
    myAccount: {
      settings: 'opc: save ' + paymentType
    }
  };
  publishEvent({ eventObject });
};

const coreSubmitSuccess = (output) => {
  const {
    curbsidePickupFlag,
    hasAppliedPromo,
    hasTaxExemptId,
    isNotifySms,
  } = output;

  // stash event
  const eventObject = {
    category: {
      primaryCategory: 'stash'
    },
    eventInfo: {
      eventName: 'opc order attributes'
    },
    eventMode: 'stash',
    stash: {
      overlayType: 'no modal',
      pickupLocation: curbsidePickupFlag ? 'curbside' : 'instore',
      pickupOption: '',
      promoCode: hasAppliedPromo ? 'Y' : 'N',
      taxExemptId: hasTaxExemptId ? 'Y' : 'N',
      textOrder: isNotifySms ? 'Y' : 'N',
    }
  };
  publishEvent({ eventObject });
};

const setAddressValidationInfo = (output) => {
  let addressValidation = {
    type: output?.validationInfo?.type,
    overlay: output?.validationInfo?.overlay,
    address: output?.validationInfo?.address,
  };
  addressValidationInteractions.push(addressValidation);
};

const setGcReloadCartPageData = (cartData) => {
  let eventObject = {};
  eventObject.cart = {
    cartID: cartData?.cartId,
    item: [
      {
        fulfillment: {
          method: 'sth'
        },
        price: {
          basePrice: '0.00',
          giftCardPrice: '00.00',
          giftCardReloadPrice: cartData?.items?.[0]?.product?.pricing?.value.toFixed(2)
        },
        productInfo: {
          manufacturer: cartData?.items?.[0]?.product?.identifiers?.productType,
          productName: cartData?.items?.[0]?.product?.identifiers?.productType,
          sku: '999999999'
        },
        quantity: '1'
      }
    ],
    price: {
      cartTotal: cartData?.items?.[0]?.product?.pricing?.value.toFixed(2)
    }
  };
  eventObject.page = {
    pageInfo: {
      pageLoadEvent: 'opc view',
      pageName: 'checkout>opc',
      pageType: 'checkout: opc',
      abTest: 'opc:version:react'
    },
    category: {
      subCategory1: 'checkout gift card reload>opc',
      subCategory2: 'checkout gift card reload >opc',
      primaryCategory: 'checkout'
    }
  };

  publishPageLoadAnalytics({ eventObject });
};

const giftcardReloadOverlay = (output) => {
  let eventObject = {};
  setCategory(eventObject, {
    primaryCategory: 'overlay'
  });
  setEventInfo(eventObject, {
    eventName: 'gift card reload'
  });
  eventObject = {
    ...eventObject,
    cart: {
      cartID: output?.cartData?.cartId
    },
    item: [
      {
        productInfo: {
          sku: '999999999'
        },
        quantity: '1',
        price: {
          basePrice: '0.00',
          giftCardPrice: '00.00',
          giftCardReloadPrice:
            output?.cartData?.items?.[0]?.product?.pricing?.value.toFixed(2)
        },
        fulfillment: {
          method: 'sth'
        }
      }
    ]
  };
  publishEvent({ eventObject });
};

const giftcardReload = (output) => {
  try {
    const cartData = output?.cartData;
    giftcardReloadOverlay(output);
    setGcReloadCartPageData(cartData);
  } catch (error) {
    console.log('Error adding giftcard reload checkout Analytics events');
  }
};

const giftcardReloadSubmit = (output) => {
  const { hasAppliedPromo, hasTaxExemptId, isNotifySms } = output;

  const eventObject = {
    category: {
      primaryCategory: 'stash'
    },
    eventInfo: {
      eventName: 'opc order attributes'
    },
    eventMode: 'stash',
    stash: {
      overlayType: 'no modal',
      promoCode: hasAppliedPromo ? 'Y' : 'N',
      taxExemptId: hasTaxExemptId ? 'Y' : 'N',
      textOrder: isNotifySms ? 'Y' : 'N',
      time: {
        bankAuthentication: '',
        paymentProcessing: ''
      }
    }
  };
  publishEvent({ eventObject });
};

const applePayClick = () => {
  const eventObject = {
    category: {
      primaryCategory: 'button submission'
    },
    eventInfo: {
      eventName: 'apple pay'
    }
  };
  publishEvent({ eventObject });
};

const applePaySubmit = (output) => {
  const {
    isRecognized = false,
    isOverlay = false,
    isSuggested = false
  } = output;

  const eventObject = {
    category: {
      primaryCategory: 'button submission'
    },
    eventInfo: {
      eventName: 'order submission'
    },
    addressValidation: [{
      type: isRecognized ? 'recognized' : 'not recognized',
      overlay: isOverlay ? 'overlay' : 'no overlay',
      address: isSuggested ? 'suggested' : 'original',
    }]
  };
  publishEvent({ eventObject });
}

const applePaySubmitSuccess = (output) => {
  const {
    hasAppliedPromo = false,
    hasTaxExemptId = false,
    isNotifySms = false,
    hasPickupLocation = false,
    hasPickupOption = false,
    bankAuthentication = '',
    paymentProcessing = ''
  } = output;

  const eventObject = {
    category: {
      primaryCategory: 'stash'
    },
    eventInfo: {
      eventName: 'opc order attributes'
    },
    eventMode: 'stash',
    stash: {
      overlayType: 'no modal',
      pickupLocation: hasPickupLocation ? 'C' : 'P',
      pickupOption: hasPickupOption ? 'Y' : 'N',
      promoCode: hasAppliedPromo ? 'Y' : 'N',
      taxExemptId: hasTaxExemptId ? 'Y' : 'N',
      textOrder: isNotifySms ? 'Y' : 'N',
      time: {
        bankAuthentication,
        paymentProcessing
      }
    }
  };
  publishEvent({ eventObject });
};

export const checkout = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('checkout-analytics', ({ output = {} }) => {
    try {
      const analyticsEventName = output?.analyticsEventName || '';
      enableCheckoutAdobeAnalytics = output?.enableCheckoutAdobeAnalytics || false;
      override = output?.override || null;

      switch (analyticsEventName) {
      case 'amerigas-cart-addition':
        amerigasStash(output);
        // amerigasCartAdd(output); // <-- need to set this up
        break;
      case 'amerigas-checkout':
        amerigasCheckout(output);
        break;
      case 'toolrental-checkout':
        toolRentalCheckout(output);
        break;
      case 'standard-checkout':
        standardCheckout(output);
        break;
      case 'exchange-checkout':
        exchangeCheckout(output);
        break;
      case EVENTS.EXCHANGE_SUBMIT:
        handleEvent('button submission', 'proceed to payment');
        break;
      case EVENTS.STANDARD_SUBMIT:
      case EVENTS.TOOL_RENTAL_SUBMIT:
      case EVENTS.AMERIGAS_SUBMIT:
        coreSubmit(output);
        break;
      case EVENTS.STANDARD_SUBMIT_SUCCESS:
      case EVENTS.TOOL_RENTAL_SUBMIT_SUCCESS:
      case EVENTS.AMERIGAS_SUBMIT_SUCCESS:
        coreSubmitSuccess(output);
        break;
      case EVENTS.ADDING_CREDIT_CARD:
        savePaymentToProfile('card');
        break;
      case EVENTS.ADDING_PAYPAL:
        savePaymentToProfile('paypal');
        break;
      case EVENTS.DELIVERY_CALENDAR_CLICK:
        handleEvent('overlay', output?.calendarType);
        break;
      case EVENTS.ADDRESS_VALIDATION:
        handleEvent('overlay', 'address validation');
        break;
      case EVENTS.SCHEDULE_DELIVERY:
        handleEvent('overlay', 'schedule your delivery');
        break;
      case EVENTS.CREDIT_CARD_ERROR:
      case EVENTS.ERROR_ADVISORY:
      case EVENTS.POST_PAGE_ERROR: // ToDo: see if makes sense to use 1, preferrabley POST_PAGE_ERROR
        handleError(output);
        break;
      case EVENTS.ADDRESS_VALIDATION_INFO:
        setAddressValidationInfo(output);
        break;
      case EVENTS.CREDIT_CARD_VALIDATION:
        handleEvent('interaction', 'credit card validated');
        break;
      case EVENTS.CVV_SUPPRESSED_ON_USER_UPDATE:
        handleEvent('content display', 'cvv field suppressed');
        break;
      // case EVENTS.CVV_SUPPRESSED_ON_PAGE_LOAD:
      //   handleCvvSuppressedOnPageLoad(output);
      //   break;
      case EVENTS.CC_BIN_RANGE_RECIEVED:
        handleBinRangeReceived(output);
        break;
      case EVENTS.CC_FIRST_INTERACTION:
        handleEvent('interaction', 'opc card number field');
        break;
      case EVENTS.ORANGE_PAY_INIT:
        handleEvent('button submission', 'pay in store');
        break;
      // case EVENTS.CC_BRAND_RECIEVED:
      //   handleCreditCardBrandReceived(output);
      //   break;
      case EVENTS.FULFILLMENT_CHANGE:
        handleFulfillmentChange(output);
        break;
      case EVENTS.GIFTCARDRELOAD_CHECKOUT:
        giftcardReload(output);
        break;
      case EVENTS.GIFTCARDRELOAD_SUBMIT:
        giftcardReloadSubmit(output);
        break;
      case EVENTS.APPLEPAY_CLICK:
        applePayClick(output);
        break;
      case EVENTS.APPLEPAY_SUBMIT:
        applePaySubmit(output);
        break;
      case EVENTS.APPLEPAY_SUBMIT_SUCCESS:
        applePaySubmitSuccess(output);
        break;
      default:
        console.warn('Unable to find checkout analytics event of \'' + analyticsEventName + '\'');
        break;
      }
    } catch (error) {
      console.error('Error in checkout analytics', error);
    }
  });
};
